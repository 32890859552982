























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {ButtonType} from '@/types/button';
import {FileTransferType} from '@/enums/FileTransferType';
import {ElementUIComponentSize} from '@/types/component';
import {AxiosRequestConfig} from 'axios';

@Component({
    name: 'DownloadButton',
})
export default class DownloadButton extends Vue {

    @Prop({default: false})
    public readonly disabled!: boolean;

    @Prop({default: ''})
    public readonly alert!: string;

    @Prop({default: 'GET'})
    public readonly method!: string;

    @Prop({default: 'primary'})
    public readonly type!: ButtonType;

    @Prop({default: 'small'})
    public readonly size!: ElementUIComponentSize;

    @Prop({default: ''})
    public readonly label!: string;

    @Prop({default: 'file-o'})
    public readonly icon!: string;

    @Prop({required: true})
    public readonly url!: string;

    @Prop({default: 'Download'})
    public readonly fileName!: string;

    @Prop({default: () => FileTransferType.PDF})
    public readonly fileType!: FileTransferType;

    @Prop({default: () => ({})})
    public readonly data!: any;

    @Prop({default: () => ({})})
    public readonly extraArgs!: any;

    @Prop({default: ''})
    public readonly toolTipTitle!: string;

    private forceDownload: boolean = true;

    private config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': this.fileType.accceptHeader,
        },
        responseType: this.fileType.responseType,
    };

    @Emit('click')
    public async click(event: Event): Promise<void> {
        return this.download();
    }

    public async download(): Promise<void> {
        const data: any = typeof this.data === 'function' ? this.data() : this.data;
        try {
            const response = this.method === 'GET' ?
                await this.$axios.get(this.url, {params: data, ...this.config}) :
                await this.$axios.post(this.url, data, {params: this.extraArgs, ...this.config});

            const blob = new Blob([response.data], {type: this.fileType.accceptHeader});
            const url = window.URL.createObjectURL(blob);
            if (this.forceDownload) {
                const body = document.body;
                const link = document.createElement('a');
                link.href = url;
                link.download = `${this.fileName}.${this.fileType.extention}`;
                body.appendChild(link);
                link.click();
                body.removeChild(link);
            } else {
                window.open(url);
            }
            window.URL.revokeObjectURL(url);
        } catch (error) {
            const msg = 'Erro ao realizar download.';
            this.$toast.error(msg);
        }
    }
}
